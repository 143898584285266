/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 300px;
    text-align: center;
  }
  
  .modal-buttons {
    margin-top: 10px;
  }
  
  /* Button styles */
  .button {
    padding: 10px 20px;
    background-color: #02001c;
    color: white;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .button:hover {
    background-color: #094C6D;
  }
  