.blogdetails {
    padding: 120px 0px;
  }
  @media (max-width: 576px) {
    .blogdetails {
      padding: 120px 25px;
    }
  }
  .blogdetails .container {
    width: 100%;
    border-radius: 30px;
    box-shadow: 0px 0px 20px rgb(207, 199, 199);
    padding: 50px;
  }
  @media (max-width: 992px) {
    .blogdetails .container {
      padding: 40px;
    }
  }
  @media (max-width: 768px) {
    .blogdetails .container {
      padding: 30px;
    }
  }
  @media (max-width: 576px) {
    .blogdetails .container {
      padding: 20px;
    }
  }
  .blogdetails h1 {
    font-size: 42px;
    font-family: var(--pp-book);
    font-weight: 700;
  }
  @media (max-width: 576px) {
    .blogdetails h1 {
      font-size: 30px;
    }
  }
  .blogdetails h2 {
    padding: 12px 0px;
    font-size: 26px;
    color: black;
    line-height: 26px;
  }
  .blogdetails .banner-img {
    margin: 20px 0px;
  }
  .blogdetails .banner-img img {
    width: 100%;
    height: 600px;
  }
  @media (max-width: 992px) {
    .blogdetails .banner-img img {
      height: 400px;
    }
  }
  @media (max-width: 768px) {
    .blogdetails .banner-img img {
      height: 350px;
    }
  }
  @media (max-width: 576px) {
    .blogdetails .banner-img img {
      height: 300px;
    }
  }
.line {
    height: 2px;
    background-color: black;
    margin: 18px 0px;
  }
  .blogdetails p {
    font-size: 16px;
  }
  .blogdetails .datap {
    font-size: 20px;
  
}

.blogdetail{
 
    border-radius: 30px;
    box-shadow: 0px 0px 20px white;
    overflow-y: scroll;
}


.modal-container {
    /* display: none;  */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2); /* Semi-transparent overlay */
    justify-content: center;
    align-items: center;
    z-index: 99999;
   
  }
  
  /* Modal Content */
  .modal-content {
    background: #fff; /* White background */
    padding: 10px 20px 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Shadow for depth */
    max-width: 42%;
    max-height: 80%;
    overflow: auto;
    top:44%;
    left:30%
  }
  
  /* Show the modal when the container has the "show" class */
  .modal-container.show {
    display: flex;
  }
  .close{
    width: 100%;
    display: flex;
    justify-content: end;
  
  }
  .blogdetail {
    text-align: center;
  }
  
  .blogdetail h3 {
    margin-bottom: 0.5rem;
  }
  
  .blogdetail div {
    margin-bottom: 0.3rem;
  }