.UserDetailPage {
  overflow-y: auto;
  padding: 16px;
  height: calc(100svh - 90px);

  @media screen and (min-width: 769px) {
    width: calc(100% - 280px);
    padding: 30px;
    margin-left: 280px;
  }
}

.modalSellToken {
  .formItem {
    margin-bottom: 12px;
    .label {
      margin-bottom: 6px;
    }
  }

  .result {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      &:nth-of-type(2) {
        font-weight: 600;
      }
    }
  }
}
